<template>
  <CBox
    bg="white"
    minH="500px"
    borderRadius="10px"
    boxShadow="0px 4px 10px rgba(0,0,0,0.1)"
  >
    <CBox minH="100px">
      <CBox py="40px" px="20px">
        <CText
          whiteSpace="wrap"
          mb="10px"
          fontFamily="Montserrat"
          :fontSize="['20px', '24px', '34px', '36px']"
          fontWeight="bolder"
          color="fontMain"
        >
          Report {{ reportHeader }}
        </CText>
        <CBox w="100%">
          <CText fontFamily="Montserrat" fontSize="14px" color="fontMain">
            We will evaluate reported content based on the category you choose
            from the list below. Please take a minute to consider if your report
            is accurate to help us address the content like spam, hate speech,
            or sensitive content.
          </CText>
        </CBox>
      </CBox>
      <CBox px="20px" mb="20px" w="100%">
        <CFormControl mb="20px">
          <CFormLabel
            mb="8px"
            fontFamily="Montserrat"
            color="fontMain"
            fontSize="14px"
            >Why you report this content?</CFormLabel
          >
          <CRadioGroup v-model="input.typeId">
            <template v-for="(type, i) in currentReportTypes || []">
              <CRadio :key="i" :value="type.report_type_id">
                <CText
                  fontFamily="Montserrat"
                  color="fontMain"
                  fontSize="14px"
                  >{{ `${type.description}` }}</CText
                >
              </CRadio>
            </template>
          </CRadioGroup>
        </CFormControl>
        <CFormControl mb="20px">
          <CFormLabel
            mb="8px"
            fontFamily="Montserrat"
            color="fontMain"
            fontSize="14px"
            >Can you tell us more about this report?</CFormLabel
          >
          <CTextarea
            v-model="input.description"
            placeholder="Write the additional information here"
          />
        </CFormControl>
      </CBox>
      <CBox d="flex" justifyContent="flex-end" pb="36px" pr="20px">
        <CButton
          px="44px"
          variant="outline"
          backgroundColor="#fff"
          mr="2"
          color="main"
          borderColor="main"
          fontSize="14px"
          @click.native="redirectToResultPage"
        >
          Cancel
        </CButton>
        <ButtonBlue
          px="30px"
          :disabled="!input.typeId"
          label="Submit"
          @click.native="postReport"
        />
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import { GET_REPORT_TYPES, POST_REPORT } from "@/store/report.module";

import Swal from "sweetalert2";

import ButtonBlue from "@/components/button/ButtonBlue.vue";

export default {
  metaInfo() {
    return {
      title: `Report ${this.reportHeader}`,
      titleTemplate: "Devcode - %s",
    };
  },
  components: {
    ButtonBlue,
  },
  data() {
    return {
      reportHeader: "",
      reportContent: "",
      jobId: "",
      commentId: "",
      input: {
        typeId: "",
        description: "",
      },
    };
  },
  computed: {
    currentReportTypes() {
      return this.$store.getters.currentReportTypes;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.jobId = this.$route.params?.job_id;
        this.commentId = this.$route.params?.comment_id;
        this.setReportData();
      },
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    redirectToResultPage() {
      window.location.href = `${window.location.origin}/job/${this.jobId}`;
    },
    setReportData() {
      if (this.commentId) {
        this.reportHeader = "Comment";
        this.reportContent = "comment";
      } else if (this.jobId) {
        this.reportHeader = "Test Result";
        this.reportContent = "result";
      }
    },
    resetReportValues() {
      this.reportHeader = "";
      this.reportContent = "";
      this.jobId = "";
      this.commentId = "";
      this.input = {
        typeId: "",
        description: "",
      };
    },
    async postReport() {
      try {
        await this.$store.dispatch(POST_REPORT, {
          type: this.reportContent,
          id: this.reportContent === "comment" ? this.commentId : this.jobId,
          data: {
            report_type_id: this.input.typeId,
            description: this.input.description,
          },
        });
        Swal.fire({
          title: "Your report is submitted",
          text: "Thanks for reporting this content. Our team will check your report as soon as possible.",
          icon: "success",
          heightAuto: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.redirectToResultPage();
            this.resetReportValues();
          }
        });
      } catch (err) {
        Swal.fire({
          title: "Submit process is failed",
          text: "There is an issue when submiting your report. Please try again later.",
          icon: "error",
          heightAuto: false,
        });
      }
    },
    async getData() {
      try {
        await this.$store.dispatch(GET_REPORT_TYPES);
      } catch (err) {
        return err;
      }
    },
  },
};
</script>
